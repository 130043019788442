<template>
    <Login/>
</template>

<script>
import Login from './components/Login.vue'

export default {
    name: 'App',
    components: {
        Login
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.gradient {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

.p-toast .p-toast-message {
  background-color: #f8d7da50 !important;
  border: 0px solid !important;
}

</style>
